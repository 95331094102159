<div class="sidebar-container">
    <div class="sidebar-content">
        <div class="d-flex flex-column justify-content-between" style="height: 100%;">
            <div>
                <div class="logo-container">
                    <a class="logo-wrapper">
                        <img class="logo" [src]="('design' | env).sidebarLogo">
                        <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
                    </a>
                </div>
                <ul class="sidebar-menu">
                    <li class="sidebar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="false">
                        <a class="sidebar-link" routerLink="/work-orders">
                            <img class="icon work-orders-icon" src="assets/img/work-orders-icon.svg">
                            <span class="fs-15 font-weight-500 workflow-text">Work Orders</span>
                        </a>
                    </li>

                    <li class="sidebar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="sidebar-link" routerLink="/">
                            <img class="icon workflows-icon" src="assets/img/workflows-icon.svg">
                            <span class="fs-15 font-weight-500 workflow-text">{{ 'MAIN.SIDEBAR.WORKFLOWS' | translate }}</span>
                        </a>
                    </li>
                    <li class="sidebar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="isAdmin && this.isEditorMode && hasVideoFlow">
                        <a class="sidebar-link" routerLink="/video-flows">
                            <img class="icon video-flows-icon" src="assets/img/video-flows-icon.svg">
                            <span class="fs-15 font-weight-600 text video-text">Video Flows</span>
                        </a>
                    </li>
                    <li class="sidebar-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" *ngIf="isAdmin && isEditorMode && !('electron' | env)">
                        <a class="sidebar-link" routerLink="/users">
                            <img class="icon user-icon" src="assets/img/users-teams-icon.svg">
                            <span class="fs-15 font-weight-500 text user-text">{{ 'MAIN.SIDEBAR.USERS' | translate }}</span>
                        </a>
                    </li>
                    <li class="sidebar-item" routerLinkActive="active" *ngIf="isAdmin && this.isEditorMode">
                        <a class="sidebar-link" routerLink="/reports">
                            <img class="icon report-icon" src="assets/img/reports-icon.svg">
                            <span class="fs-15 font-weight-500 text">{{ 'MAIN.SIDEBAR.REPORTS' | translate }}</span>
                        </a>
                    </li>
                    <li class="sidebar-item" routerLinkActive="active" *ngIf="!isAdmin || (isAdmin && !isEditorMode)">
                        <a class="sidebar-link" routerLink="/user-reports">
                            <img class="icon report-icon" src="assets/img/reports-icon.svg">
                            <span class="fs-15 font-weight-500 text">{{ 'MAIN.SIDEBAR.DRAFTS' | translate }}</span>
                        </a>
                    </li>
                   <li class="sidebar-item" routerLinkActive="active" *ngIf="isAdmin && isEditorMode && webhooksAvailable && ('design' | env).showSettings">
                        <a class="sidebar-link" routerLink="/settings">
                            <img class="icon settings-icon" src="assets/img/settings-white.svg">
                            <span class="fs-15 font-weight-600 text">{{ 'MAIN.SIDEBAR.SETTINGS' | translate }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            
            <div class="container">
                <div class="d-flex justify-content-center align-items-center switch" *ngIf="isAdmin">
                    <a class="fs-14 font-weight-600 switch-mode text-center" (click)="switchMode(true)" *ngIf="!isEditorMode">
                        {{ 'MAIN.SIDEBAR.SWITCH-ADMIN-PANEL' | translate }}
                        <img src="assets/img/newinfo-icon.svg" height="17" class="ms-1" [dynamicTooltip]="('MAIN.SIDEBAR.SWITCH-ADMIN-PANEL-TEXT' | translate )"  placement="right">
                    </a>
                    <a class="fs-14 font-weight-600 switch-mode text-center" (click)="switchMode(false)" *ngIf="isEditorMode">
                        {{ 'MAIN.SIDEBAR.SWITCH-USER-PANEL' | translate }}
                        <img src="assets/img/newinfo-icon.svg" height="17" class="ms-1" [dynamicTooltip]="('MAIN.SIDEBAR.SWITCH-USER-PANEL-TEXT' | translate )"  placement="right">
                    </a>
                </div>

                <div class="language-and-profile">
                    <div class="language-dropdown w-100" dropdown #dropdown="bs-dropdown" >
                        <a id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                            <div class="d-flex align-items-center justify-content-between w-100">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/language-icon.svg">
                                    <span class="name fs-14 font-weight-500">{{ currentLang.code | uppercase }}</span>
                                </div>
                                <img id="dropdown-icon" src="assets/img/arrow-right-big.svg" class="ms-2" >
                            </div>
            
                        </a>
                        <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                            <ng-container *ngFor="let lang of allLangs">
                                <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                    <a class="dropdown-item language-link px-2 fs-14 font-weight-500" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    
                    <div class="profile-wrapper">
                        <a class="avatar-wrapper">
                            <img class="avatar" src="assets/img/newprofile-icon.svg">
                        </a>
                        <div class="dropdown-wrapper" dropdown>
                            <a class="name-wrapper dropdown-toggle" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                                <span class="name fs-14 font-weight-500">{{ user.name }}</span>
                                <img id="dropdown-icon" src="assets/img/arrow-right-big.svg" class="ms-2 right-icon" >
            
                            </a>
                            <div class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item fs-14 disabled" role="menuitem">v{{ version }}</a>
                                <a class="dropdown-item fs-14" role="menuitem" (click)="onLogout()">{{ 'MAIN.NAVBAR.LOGOUT.TEXT' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>