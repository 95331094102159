<div class="error-page-container">
    <div class="logo-container">
        <img class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
        <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
    </div>
    <ng-container [ngSwitch]="error">
        <div *ngSwitchCase="'account-not-found'" class="error-page-title mt-5 mb-3 px-3 text-center">{{ 'APP.EXTERNALS.AD_ERROR.ACCOUNT_NOT_FOUND' | translate }}</div>
        <div *ngSwitchCase="'permission-denied'" class="error-page-title mt-5 mb-3 px-3 text-center">{{ 'APP.EXTERNALS.AD_ERROR.PERMISSION_DENIED' | translate }}</div>
        <div *ngSwitchDefault class="error-page-title mt-5 mb-3 px-3 text-center">{{ 'APP.EXTERNALS.AD_ERROR.INTERNAL_ERROR' | translate }}</div>
    </ng-container>
    <div class="error-page-message px-3" [innerHTML]="'APP.EXTERNALS.AD_ERROR.CONTACT_MAIL' | translate:('design' | env)"></div>
</div>