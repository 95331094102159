import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from 'src/environments/environment';

import { AuthGuard } from './guards/auth.guard';
import { GuestAuthGuard } from './guards/guest-auth.guard';

import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/main/content/dashboard/dashboard.component';
import { LoginFromAdminComponent } from './components/externals/login-from-admin/login-from-admin.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MobileReportComponent } from './components/externals/mobile-report/mobile-report.component';
import { ReportListComponent } from './components/main/content/report-list/report-list.component';
import { SettingsComponent } from './components/main/content/settings/settings.component';
import { LoginComponent } from './components/login/login.component';
import { UserListComponent } from './components/main/content/user-list/user-list.component';
import { ReportFileComponent } from './components/externals/report-file/report-file.component';
import { UserReportsComponent } from './components/main/content/user-reports/user-reports.component';
import { GuestLoginWithLinkComponent } from './components/externals/guest-login-with-link/guest-login-with-link.component';
import { GuestWorkflowComponent } from './components/externals/guest-workflow/guest-workflow.component';
import { RemoteWorkflowComponent } from './components/externals/remote-workflow/remote-workflow.component';
import { SignatureReportComponent } from './components/externals/signature-report/signature-report.component';
import { WebviewLoginComponent } from './components/externals/webview-login/webview-login.component';
import { WorkOrdersComponent } from './components/main/content/work-orders/work-orders.component';
import { VideoFlowDashboardComponent } from './components/main/content/video-flow-dashboard-2/video-flow-dashboard-2.component';
import { WorkOrderDetailComponent } from './components/main/content/work-orders/work-order-detail/work-order-detail.component';
import { AdLoginComponent } from './components/externals/ad-login/ad-login.component';
import { AdErrorComponent } from './components/externals/ad-error/ad-error.component';

const routes: Routes = [
  { path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'reports', component: ReportListComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'users', component: UserListComponent },
      { path: 'report-file', component: ReportFileComponent},
      { path: 'user-reports', component: UserReportsComponent},
      { path: 'video-flows', component: VideoFlowDashboardComponent},
      { path: 'work-orders', component: WorkOrdersComponent},
      { path: 'work-order-detail', component: WorkOrderDetailComponent},
      { path: 'video-flows', component: VideoFlowDashboardComponent},
    ]
  },
  { path: 'guest-workflow', component: GuestWorkflowComponent, canActivate: [GuestAuthGuard]},
  { path: 'show-workflow', component: RemoteWorkflowComponent},
  { path: 'login', component: environment.design.webviewEnvironment ? WebviewLoginComponent : LoginComponent },
  { path: 'adlogin', component: AdLoginComponent },
  { path: 'aderror', component: AdErrorComponent },
  { path: 'mobile-report', component: MobileReportComponent },
  { path: 'work-order-detail-mobile', component: WorkOrderDetailComponent},
  { path: 'show-report', component: SignatureReportComponent },
  { path: 'login-from-admin', component: LoginFromAdminComponent},
  { path: 'share', component: GuestLoginWithLinkComponent},
  { path: 'videoflow', component: GuestLoginWithLinkComponent},
  { path: '**', component: environment.design.webviewEnvironment ? WebviewLoginComponent : NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
