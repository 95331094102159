import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-ad-error',
  templateUrl: './ad-error.component.html',
  styleUrls: ['./ad-error.component.scss']
})
export class AdErrorComponent implements OnInit {

  error = "internal-error";

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(first()).toPromise()
    .then(params => {
      if (params && params['error']) {
        this.error = params['error'];
      }
    });
  }
}
